import ModalityRepository from '@/shared/http/repositories/socialProject/modality'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import Modality from '@/shared/models/modality'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovaModalidade',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Modalidade',
    modality: new Modality(),
    isNew: true
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Modalidades',
            href: this.$router.resolve({ name: 'Modality' }).href
          },
          {
            text: 'Nova modalidade',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(modality) {
      loading.push()
      ModalityRepository.Create(modality)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          loading.pop()
          toast.success('Modalidade criada com sucesso', 'NOVA MODALIDADE')
          Promise.resolve()
          return this.$router.replace({ name: 'Modality' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar modalidade', 'ERRO')
        })
    }
  }
}
